<template>
  <div class="search-box">
    <el-input v-model="keyWord" placeholder="请输入要搜索的商品" @keyup.native.enter="search"></el-input>
    <el-button icon="el-icon-search" @click="search">搜索</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyWord: "" //搜索关键字
    };
  },
  created() {
    if (this.$route.query.keyWordName) {
      this.keyWord = this.$route.query.keyWordName;
    }
  },
  methods: {
    search() {
      let routeData = this.$router.resolve({
        path: "/searchProduct",
        query: { keyWordName: this.keyWord }
      });
      this.keyWord = "";
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 600px;
}
.search-box ::v-deep input {
  color: $colorA;
  border: 3px solid $colorA;
  border-radius: 0;
  // border-top-right-radius: 0px;
  // border-bottom-right-radius: 0px;
  border-right: none;
}
.search-box ::v-deep button {
  width: 100px;
  height: 42px;
  background-color: $colorA;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.search-box ::v-deep input:focus {
  color: $colorA;
  border: 3px solid $colorA;
  border-right: none;
}
.search-box ::v-deep button:focus {
  color: #ffffff;
  border-color:$colorA;
}
.search-box ::v-deep .el-input {
  width: 400px;
}
</style>